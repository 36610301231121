import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { SurveyService } from './SurveyService';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { IElementLibraryGroupItem } from '../domain/surveys/element-library/IElementLibraryGroup';
import { IBaseItem } from '../domain/surveys/items/BaseItem';
import { ElementLibraryService } from './ElementLibraryService';
import { Survey } from '../domain/surveys/survey/Survey';
import { MatrixQuestion } from '../domain/surveys/items/Matrix';

@Service('its.survey.services', TokenTransformerService.IID, TokenTransformerService)
export class TokenTransformerService {
    static IID: string = 'itsTokenTransformerService';
    static $inject: Array<string> = ['itsSurveyService', 'itsElementLibraryService', '$q'];

    public constructor(
        private surveyService: SurveyService,
        private elementLibraryService: ElementLibraryService,
        private q: ng.IQService
    ) {}

    public transformToChips(stringToTransform: string): ng.IPromise<string> {
        const tokens: Array<string> = stringToTransform.match(/\[\[([a-zA-Z0-9._-]*)]]/g);
        let transformedString: string = stringToTransform;
        const defer: ng.IDeferred<any> = this.q.defer();
        if (!tokens) {
            defer.resolve(stringToTransform);
            return defer.promise;
        }

        this.surveyService.getSurveyPromise().then((survey: Survey) => {
            for (const token of tokens) {
                if (this.isSurveyQuestionToken(token)) {
                    transformedString = transformedString.replace(token, this.buildQuestionHtml(survey, token));
                } else if (this.isCalendarToken(token)) {
                    const strippedBracketToken: string = this.stripBrackets(token);
                    transformedString = transformedString.replace(token, this.buildCalendarHtml(strippedBracketToken));
                } else if (this.isContactToken(token)) {
                    const strippedBracketToken: string = this.stripBrackets(token);
                    transformedString = transformedString.replace(token, this.buildContactHtml(strippedBracketToken));
                }
            }
            defer.resolve(transformedString);
        });

        return defer.promise;
    }

    private isSurveyQuestionToken(token: string): boolean {
        return token.indexOf('[[survey.question') === 0;
    }

    private isCalendarToken(token: string): boolean {
        return token.indexOf('[[calendar.') === 0;
    }

    private isContactToken(token: string): boolean {
        return token.indexOf('[[contact.') === 0;
    }

    private getSurveyElementUuid(token: string): string {
        const tokenSplitByPeriod: Array<string> = token.split('.');
        return String.isValidUuid(tokenSplitByPeriod[2]) ? tokenSplitByPeriod[2] : null;
    }

    private getTokenTargetTypeSuffix(token: string): string {
        const strippedBracketToken: string = this.stripBrackets(token),
            tokenSplitByPeriod: Array<string> = strippedBracketToken.split('.');
        return this.transformCamelCaseToCapital(tokenSplitByPeriod[tokenSplitByPeriod.length - 1]);
    }

    private stripBrackets(token: string): string {
        return token.replace(/(\[\[)|(]])/g, '');
    }

    private transformCamelCaseToCapital(stringToTransform: string): string {
        const snakeString: string = String.toSnake(stringToTransform);
        const whiteSpaceString: string = snakeString.replace(/_/g, ' ');
        return String.capitalize(whiteSpaceString);
    }

    private buildCalendarHtml(token: string): string {
        return this.buildBaseChipHtml('access_time', token);
    }

    private buildQuestionHtml(survey: Survey, token: string): string {
        const questionUuid: string = this.getSurveyElementUuid(token),
            element: IBaseItem = survey.getItemByUuid(questionUuid);

        if (element) {
            const elementType: string = this.getElementType(element);
            const elementLibraryItem: IElementLibraryGroupItem =
                this.elementLibraryService.getSurveyElementByType(elementType);
            const tokenTypeSuffix: string = this.getTokenTargetTypeSuffix(token);
            const chipText: string = elementLibraryItem.label + ' - ' + tokenTypeSuffix;
            return this.buildBaseChipHtml(elementLibraryItem.icon, chipText, element.label);
        } else {
            return this.buildBaseChipHtml('question_answer', token);
        }
    }

    private buildContactHtml(token: string): string {
        return this.buildBaseChipHtml('person', token);
    }

    private buildBaseChipHtml(icon: string, text: string, tooltip?: string): string {
        const tooltipHtml: string = tooltip ? '<md-tooltip>' + tooltip + '</md-tooltip> ' : '';
        return (
            '<div class="its-chip">' +
            tooltipHtml +
            '<md-icon class="its-chip--icon its-clickable">' +
            icon +
            '</md-icon> ' +
            '<div class="its-chip--text">' +
            text +
            '<md-tooltip>' +
            text +
            '</md-tooltip></div></div>'
        );
    }

    private getElementType(element: IBaseItem): string {
        if (element instanceof MatrixQuestion) {
            return 'matrix';
        }

        return element.type;
    }
}
